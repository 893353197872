<template>
  <form class="wrapper" ref="form">
    <div class="observer">
      <img
        src="../../resource/img/modal/close2.svg"
        alt="close"
        @click="close"
        class="close"
      />
      <div class="observer__wrapper">
        <!--  <h3> Старый вариант </h3>
       <table class="table">
          <thead>
            <tr>
              <th scope="col">Пробег(ч/б)</th>
              <th scope="col">Дата</th>
              <th scope="col">Значение</th>
              <th scope="col">Автор</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(color, index) in wear" :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ formatDate(color.timestamp) }}</td>
              <td>{{ color.value }}</td>
              <td v-if="color.user">
                {{ color.user.firstName + " " + color.user.lastName }}
              </td>
              <td v-else>{{ "-" }}</td>
            </tr>
          </tbody>
        </table>

        <table class="table">
          <thead>
            <tr>
              <th scope="col">Пробег(цвет)</th>
              <th scope="col">Дата</th>
              <th scope="col">Значение</th>
              <th scope="col">Автор</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(color, index) in color" :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ formatDate(color.timestamp) }}</td>
              <td>{{ color.value }}</td>
              <td v-if="color.user">
                {{ color.user.firstName + " " + color.user.lastName }}
              </td>
              <td v-else>{{ "-" }}</td>
            </tr>
          </tbody>
        </table> 

                <h3> Новый вариант </h3> -->

        <table class="table">
          <thead>
            <tr>
              <th scope="col">Дата</th>
              <th scope="col">Пробег(ч/б)</th>
              <th scope="col">Пробег(цвет)</th>
              <th scope="col">Автор изменения</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in counterHistoryData2" :key="index">
              <td>{{ formatDate(item.timestamp) }}</td>
              <td>{{ formatter.format(item.valueWear) }}</td>
              <td>{{ formatter.format(item.valueColor) }}</td>
              <td v-if="item.user">
                {{ item.user.firstName + " " + item.user.lastName }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </form>
</template>

<script>
import moment from "moment";

export default {
  name: "CounterHistoryModal",
  props: ["counterHistoryData"],
  emits: [],
  data() {
    return {
      wear: [],
      color: [],
      counterHistoryData2: [],
      formatter: new Intl.NumberFormat("ru")
    };
  },
  computed: {
    authorName() {
      if (this.color.user === undefined) {
        return "";
      } else {
        return this.color.user.firstName + " " + this.color.user.lastName;
      }
    }
  },

  methods: {
    formatDate(timestamp) {
      return moment(timestamp).format("L");
    },

    counterData() {
      console.log("this.counterHistoryData", this.counterHistoryData);

      this.counterHistoryData.forEach(el => {
        if (el.codeName === "wear") {
          this.wear.push(el);
        }

        if (el.codeName === "color") {
          this.color.push(el);
        }
      });

      this.wear.forEach(el => {
        let el1 = {};

        el1.timestamp = el.timestamp;
        el1.valueWear = el.value;
        el1.user = el.user;

        this.color.forEach(el2 => {
          if (
            el1 &&
            el2 &&
            el1.timestamp &&
            el2.timestamp &&
            this.formatDate(el1.timestamp).slice(0, 13) ==
              this.formatDate(el2.timestamp).slice(0, 13)
          ) {
            el1.valueColor = el2.value;
          }
        });

        this.counterHistoryData2.push(el1);

        console.log("this.counterHistoryData2", this.counterHistoryData2);
      });
    },

    // formatTypeAction(type) {
    //   if (type === "wear") {
    //     return "Пробег(ч/б)";
    //   }
    //   if (type === "color") {
    //     return "Пробег(цвет)";
    //   }
    // },

    close() {
      this.$emit("close");
    }
  },

  mounted() {
    this.counterData();
  },

  created() {
    console.log(this.color.user);
    // console.log(123);
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #3c3c3cc4;
  z-index: 1;
}

.observer {
  padding: 66px 0 82px 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: auto;
  background: var(--main-card-color);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  z-index: 1;
  &__select {
    position: relative;
    &__img {
      position: absolute;
      top: calc(50% - 7px);
      right: -60px;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
  &__wrapper {
    max-width: 850px;
    margin: 0 auto;
    height: 350px;
    overflow-y: scroll;
  }
  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 12px;
    height: 12px;
  }
  &__input {
    width: 350px;
    height: 46px;
    background: #f1f1f3;
    border-radius: 4px;
  }
  .executor__wrapper__button__container {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;

    button {
      width: 49%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 46px;
      background: #2c698c;
      border-radius: 4px;
      border: none;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 15px;
      text-align: center;
      letter-spacing: -0.333333px;
      color: #ffffff;
    }
  }
}

.choose {
  margin-bottom: 15px;
}
</style>
