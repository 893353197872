<!-- device.title -->
<template>
  <TepmplateBlock
    mod-class
    content-class="mytechnique-wrap mt-5"
    :title-page="currentDevice.title"
  >
    <template #titleAppend>
      <IconPlus @clicked="createRequest" />
    </template>
    <div class="scroll-container">
      <vuescroll>
        <div class="tehnique-wrap">
          <div class="col-3 tehnique-block">
            <div class="tehnique-block__img-block">
              <router-link
                class="text-center mb-3 d-block"
                :to="{
                  name: 'EditTehniquePage',
                  params: {
                    id: this.$route.params.id
                  }
                }"
              >
                <!-- {{ currentDevice }} -->
                Редактировать
              </router-link>

              <!--
              <img
                class="tehnique-block__img"
                
                :src="
                  currentDevice.picturePath
                    ? picture(currentDevice.picturePath)
                    : require('../../../resource/img/placeholder.png')
                "
                
                alt=""
              />
              -->
              <img
                v-if="currentDevice"
                class="tehnique-block__img"
                :src="getImageDeviceOrTechnique()"
                alt=""
              />
              <h2 class="tehnique-block__title tehnique-block__title_center">
                {{ currentDevice.title }}
                <help-popover message="Модель аппарата" />
              </h2>
              <div class="tehnique-block__id">
                {{ currentDevice.serialNum }}
                <help-popover message="Серийный номер аппарата" />
              </div>
              <div style="height:10px"></div>
              <div class="tehnique-block__id">
                {{ currentDevice.stickerNum }}
                <help-popover message="Стикер номер аппарата" />
              </div>

              <div style="height:10px"></div>
              <div class="tehnique-block__id">
                {{ currentDevice.specialMarks }}
                <help-popover message="Инвентарный номер аппарата" />
              </div>
            </div>
          </div>
          <div class="tehnique-block w-75">
            <div class="tehnique-block__info-block">
              <div
                class="tehnique-block__info mb-4 d-flex flex-column align-items-start"
              >
                <div class="d-flex justify-content-between w-100">
                  <h2 class="tehnique-block__title mb-2">
                    Ресурс
                    <help-popover
                      message="Рекомендованный производителем максимальный ресурс(время службы)"
                    />
                  </h2>
                  <button class="qr-button" @click="showCounterHistoryModal">
                    История пробега
                  </button>
                </div>

                <p class="mb-0">
                  {{ currentDevice.resource || "Нет данных" }}
                </p>
              </div>

              <div class="tehnique-block__info  mb-4">
                <h2 class="tehnique-block__title mb-0 w-25">
                  Пробег
                  <span class="d-block"
                    >(ч/б)
                    <help-popover
                      message="Кол-во черно-белых страниц, напечатанных аппаратом."
                  /></span>
                </h2>
                <div
                  class="d-flex w-100 align-items-center justify-content-between"
                >
                  <p class="tehnique-block__mileage mb-0 w-75">
                    {{ pageWear || "Нет данных" }}
                  </p>

                  <button class="qr-button" @click="showModalOrder">
                    Создать задачу
                  </button>
                </div>
              </div>

              <div class="tehnique-block__info  mb-4">
                <h2 class="tehnique-block__title mb-0 w-25">
                  Пробег
                  <span class="d-block"
                    >(цвет)
                    <help-popover
                      message="Кол-во цветных страниц, напечатанных аппаратом."
                    />
                  </span>
                </h2>
                <div
                  class="d-flex w-100 align-items-center justify-content-between"
                >
                  <p class="tehnique-block__mileage mb-0 w-75">
                    {{ pageColorWear || "Нет данных" }}
                  </p>

                  <button class="qr-button" style="font-size: 13px">
                    Подобрать аналог
                  </button>
                </div>
              </div>

              <div class="tehnique-block__info  mb-4">
                <h2 class="tehnique-block__title mb-0 w-25">
                  Стоимость печати страницы чб. (руб.коп)
                </h2>
                <div
                  class="d-flex w-100 align-items-center justify-content-between"
                >
                  <p class="tehnique-block__mileage mb-0 w-75">
                    {{ currentDevice.costPerPage }}
                  </p>

                  <button
                    v-if="canPrintQr"
                    @click="doShowQrParamsModal"
                    class="qr-button"
                    title="Скачать наклейку и наклеить на устройтво
                  для идентификации техники. "
                  >
                    Скачать QrCode
                    <help-popover
                      message="Тут можно скачать наклейку, что бы наклеить ее на аппарат и делать задачи через QR код."
                      class="ml-2"
                    />
                  </button>
                </div>
              </div>

              <div class="tehnique-block__info mb-4">
                <h2 class="tehnique-block__title mb-0 w-75">
                  Стоимость печати страницы цвет. (руб.коп)
                </h2>
                <div
                  class="d-flex w-100 align-items-center justify-content-between"
                >
                  <p class="tehnique-block__mileage mb-0 w-75 no-button-row">
                    {{ currentDevice.costPerPageColor }}
                  </p>
                </div>
              </div>

              <div class="tehnique-block__info  mb-4">
                <h2 class="tehnique-block__title mb-0 w-25">
                  Расходы на аппарат
                </h2>
                <div
                  class="d-flex w-100 align-items-center justify-content-between"
                >
                  <p class="tehnique-block__mileage mb-0 w-75">
                    {{ currentDevice.expenses }}
                  </p>
                </div>
              </div>

              <div
                class="tehnique-block__info d-flex flex-column align-items-start"
              >
                <h2 class="tehnique-block__title">
                  Тег
                  <help-popover
                    message="Тег. Добавляется вручную (например: '11 кабинет', 'для дизайнеров', 'под списание', и тд)"
                  />
                </h2>
                <div class="tehnique-block__info__geotags d-flex flex-wrap">
                  <div
                    v-for="(tag, index) in currentDevice.tags"
                    :key="index"
                    class="geotag-item mr-4 mt-2"
                  >
                    {{ tag.title }}
                    <span
                      class="delete-tag-icon"
                      @click="deleteTagClick(tag.id)"
                      >x</span
                    >
                  </div>
                  <div>
                    <button
                      v-show="!editGeotags"
                      @click="addGeotag"
                      class="geotag-plus-btn mt-2 mr-2"
                    >
                      <div>+</div>
                    </button>
                    <input
                      ref="tagInput"
                      @keyup.enter="inputTag"
                      v-model="tagName"
                      v-if="editGeotags"
                      class="geotag-input  mt-2"
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tehnique-info-wrap">
          <h2 class="tehnique-block__title mb-3">
            Местоположение
            <help-popover message="Адрес офиса к которому прикреплен аппарат" />
          </h2>

          <address
            class="tehnique-block__address mb-4"
            v-if="currentDevice.officeId"
          >
            <img
              src="../../resource/img/geo.svg"
              alt=""
              class="tehnique-block__address-img"
            />

            Офис: "{{ office_name }}", {{ office_city }}, {{ office_street }},
            {{ office_home_num }}
          </address>

          <!-- <h2 class="tehnique-block__title mb-3">Особые отметки</h2>
          <p class="tehnique-block__info mb-4">
            {{ currentDevice.specialMarks || "" }}
          </p> -->

          <div v-if="ordersWithThisDevice.length" class="tehnique-btn-wrap">
            <h2 class="tehnique-block__title mb-3">
              История аппарата
              <help-popover message="История заявок по данному аппарату" />
            </h2>

            <div
              v-for="item in ordersWithThisDevice"
              :key="item.id"
              class="d-flex"
            >
              <router-link
                class="device-history"
                :to="{ name: 'Order', params: { id: item.id } }"
              >
                {{ generateDate(item.createdAt) }} {{ item.title }}
                <span class="author">{{ getAuthor(item) }}</span>
              </router-link>
            </div>
          </div>

          <div class="tehnique-wrap" v-if="technique && category">
            <div class="tehnique-block">
              <h2 class="tehnique-block__title mb-3">Параметры</h2>
              <div
                v-for="(param, index) of technique.parameters"
                :key="index"
                class="colored_row d-flex justify-content-between"
              >
                <span class="font-weight-bold"
                  >{{ getTitle(param.code) }}:</span
                >
                <span class="col-fixed-width">{{
                  getParamValue(param.value)
                }}</span>
              </div>
            </div>
          </div>

          <!--          <h2 class="tehnique-block__title mb-3">-->
          <!--            История аппарата-->
          <!--            <help-popover message="История заявок по данному аппарату" />-->
          <!--          </h2>-->
          <div class="tehnique-btn-wrap row">
            <!--            <div-->
            <!--              v-for="item in currentDevice.history"-->
            <!--              :key="item.id"-->
            <!--              class="tehnique-block__btn-item col-4"-->
            <!--            >-->
            <!--              <button-->
            <!--                class="btn btn-primary mr-2 tehnique-block__btn"-->
            <!--                @click="goToChat(item)"-->
            <!--              >-->
            <!--                {{ generateDate(item.timestamp) }}-->
            <!--              </button>-->
            <!--              <span class="tehnique-block__btn-text">{{-->
            <!--                item.description-->
            <!--              }}</span>-->
            <!--            </div>-->

            <div class="w-100 mt-5 mb-3"></div>

            <div style="display:none">
              <h3 class="text-center w-100 mt-5 mb-3">
                Атрибуты и параметры устройства
              </h3>
              <AttributesTable page="SingleDevice" :options="attributesProp" />
            </div>
          </div>
        </div>
      </vuescroll>
    </div>

    <CounterHistoryModal
      :counterHistoryData="counterHistoryData"
      @close="closeCounterHistoryModal"
      v-if="isShowCounterHistoryModal"
    />

    <PlusButton
      ref="plusButton"
      @add-order="onAddOrder"
      link="/my-techique/chat"
      :device="currentDevice"
      title="Создать задачу на это устройство"
    />

    <b-modal id="qr-params-modal" hide-footer>
      <template #modal-title> Печать QrCode {{ isNeoPharm }} </template>
      <div class="d-block">
        <template v-if="isNeoPharm">
          <b-form-group label="Введите URL для QR-кода" label-for="qr-url">
            <b-form-input
              v-model="currentQrURL"
              id="qr-url"
              type="text"
              v-on:keyup.enter="onDownloadClick"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Введите название компании для QR-кода"
            label-for="qr-url"
          >
            <b-form-input
              v-model="currentQrName"
              id="qr-url"
              type="text"
              v-on:keyup.enter="onDownloadClick"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Введите телефон компании для QR-кода"
            label-for="qr-url"
          >
            <b-form-input
              v-model="currentQrPhone"
              id="qr-url"
              type="text"
              v-phone
              v-on:keyup.enter="onDownloadClick"
            ></b-form-input>
          </b-form-group>
        </template>
      </div>
      <div class="mt-3 d-flex align-items-center justify-content-end">
        <button class="btn btn-second" @click="onDownloadClick">
          <span v-if="!isQrCodesPrinting">Печать QrCode</span>
          <b-spinner small v-else />
        </button>
        <button class="btn btn-primary ml-2" @click="doHideQrParamsModal">
          Отменить
        </button>
      </div>
    </b-modal>
  </TepmplateBlock>
</template>

<script>
import TepmplateBlock from "../../components/TepmplateBlock";
import RequestManager from "../../function/request/RequestManager";
import AttributesTable from "../../components/AttributesTable";
import PlusButton from "../../components/PlusButton";
// import { saveAs } from "file-saver";
import { mapGetters, mapState } from "vuex";
import vuescroll from "vuescroll";
import HelpPopover from "../../components/HelpPopover";
import IconPlus from "../../components/Form/IconPlus";
import CounterHistoryModal from "../../components/Modal/CounterHistoryModal";
import {
  CategoryApi,
  CounterApi,
  TechniqueApi,
  DeviceApi,
  OfficeApi
} from "../../function/request/apiV2";
import { OrdersApi } from "../../function/request/apiV2";

export default {
  name: "TehniquePage",
  components: {
    IconPlus,
    HelpPopover,
    TepmplateBlock,
    AttributesTable,
    vuescroll,
    CounterHistoryModal,
    PlusButton
  },
  async created() {
    /* RequestManager()
      .getSingleDevice(this.$route.params.id)
      .then(data => {
        const device = data;
        for (let key in device) {
          if (key === "attributes") {
            // device[key] = JSON.parse(device[key]);
          }
        }
        this.device = device;

        this.getTechnique();
      }); */

    /*this.getTechnique();*/

    await this.$store.dispatch("devices/getDevice", this.$route.params.id);
    this.device = this.$store.state.devices.currentDevice;
    await this.getTechnique();

    this.qrCodeStr =
      process.env.VUE_APP_API_URL +
      "/devices/" +
      this.$route.params.id +
      "/qr/";
  },
  data() {
    return {
      isNeoPharm: false,
      currentQrURL: "",
      currentQrPhone: "",
      currentQrName: "",
      isQrCodesPrinting: false,
      ordersList: [],
      count: 0,
      ordersApi: new OrdersApi(),
      CounterApi: new CounterApi(),
      DeviceApi: new DeviceApi(),
      OfficeApi: new OfficeApi(),
      techniqueApi: new TechniqueApi(),
      categoryApi: new CategoryApi(),
      paramTitleIsLoading: false,
      office_name: "",
      office_city: "",
      office_street: "",
      office_home_num: "",
      geoTags: [],
      qrImage: "",
      downLoadIcon: require("../../resource/img/download-qr.svg"),
      defaultPictureDevice: require("../../../resource/img/placeholder.png"),
      qrCodeStr: "",
      qrCode: "",
      editGeotags: false,
      tagName: "",
      showHistory: false,
      technique: null,
      category: null,
      device: {
        picturePath: null,
        title: "",
        status: "2",
        type: "",
        serialNum: "",
        stickerNum: "",
        office: {},
        resource: "Resourse",
        wear: 111,
        tags: [],
        specialMarks: "",
        attributes: []
      },
      tableInfo: [],
      ordersWithThisDevice: [],
      wear: ["wear", "color"],

      pageWear: "",
      pageColorWear: "",
      counterHistoryData: [],
      isShowCounterHistoryModal: false
    };
  },
  computed: {
    ...mapGetters(["officeList", "tagsList", "currentUser"]),
    ...mapState("devices", ["currentDevice", "devicesTypes", "devicesStatus"]),
    attributesProp() {
      //костыль для того, чтобы нивелировать факт пробрасывания раздных DTO с бэка
      // toDo - fix back
      let arr = this.device.attributes || [];

      if (typeof arr == "string") {
        //если пришел массив объектов в формате ДЖЕЙСОН (но хз - есть ли кейсы с объектами в этом формате)
        return JSON.parse(arr);
      } else {
        const isArray = arr.length >= 0;
        if (isArray) {
          return arr;
        } else {
          //часть девайсов приходит с атрибутами в формате объекта - переделываем в массив объектов
          return Object.entries(arr).map(([key, val]) => ({
            title: key,
            description: val
          }));
        }
      }
    },

    canPrintQr() {
      // return ["1", "2"].includes(this.currentUser.role);
      return true;
    }
  },
  methods: {
    doShowQrParamsModal() {
      if (this.isNeoPharm) {
        this.$bvModal.show("qr-params-modal");
      } else {
        this.onDownloadClick();
      }
    },
    doHideQrParamsModal() {
      this.$bvModal.hide("qr-params-modal");
    },
    getImageDeviceOrTechnique() {
      if (this.currentDevice?.picturePath?.length > 0) {
        return this.picture(this.currentDevice.picturePath);
      } else {
        if (this.currentDevice?.files) {
          return this.currentDevice.files[this.currentDevice.files.length - 1]
            .url;
        } else {
          return this.defaultPictureDevice;
        }
      }
    },
    async getOrders() {
      this.ordersList = [];

      try {
        const { count, items } = await this.ordersApi.api.listOrders({
          companyId: this.$store.getters.currentUser.company,
          limit: 10000000,
          devicesIds: [this.currentDevice.id.toString()]
        });
        this.count = count;
        this.ordersList = [...items];
        this.ordersWithThisDevice = this.ordersList;
      } catch (e) {
        throw Error(e);
      } finally {
        /*   
             this.orders = this.uniqBy(this.orders, "id");
      this.ordersWithThisDevice.sort(function(a, b) {
              return new Date(b.created) - new Date(a.created);
            });*/
      }
    },

    picture(path) {
      return process.env.VUE_APP_URL + "/media/" + path;
    },

    async get_offices() {
      let allOffices;

      if (this.currentDevice.companyId) {
        let cIds = [];
        cIds[0] = parseInt(this.currentDevice.companyId);

        const { items } = await this.OfficeApi.api.listOffice({
          limit: 10000,
          companyIds: cIds
        });

        allOffices = items;

        let idOffice = this.currentDevice.officeId;

        let office = allOffices.find(item => item.id == idOffice);

        if (office !== null && office !== undefined) {
          this.office_name = office.name;
          this.office_city = office.city;
          this.office_street = office.street;
          this.office_home_num = office.homeNum;
        }
      }
    },

    showCounterHistoryModal() {
      this.isShowCounterHistoryModal = true;
    },

    closeCounterHistoryModal() {
      this.isShowCounterHistoryModal = false;
    },

    async getTechniqueCounters() {
      this.CounterApi.api
        .deviceCounters(this.currentDevice.id, {
          codeNames: this.wear
        })
        .then(data => {
          /* if (data !== undefined && data.length > 0 && data[1]?.value)
            this.pageWear = data[1].value;
          if (data !== undefined && data.length > 1 && data[0]?.value)
            this.pageColorWear = data[0].value; */
          const formatter = new Intl.NumberFormat("ru");
          if (data && data.length) {
            data.forEach(item => {
              if (item.codeName === "wear") {
                this.pageWear = formatter.format(Number(item.value));
              }
              if (item.codeName === "color") {
                this.pageColorWear = formatter.format(Number(item.value));
              }
            });
          }
        });
    },

    getDevice() {
      this.DeviceApi.api.getDevice(this.$route.params.id);
    },

    async getTechniqueCountersHistory() {
      this.CounterApi.api
        .deviceCountersHistory(this.currentDevice.id, {
          codeNames: this.wear
        })
        .then(res => {
          this.counterHistoryData = res.map(item => {
            return { ...item, id: 1 };
          });
        });
    },

    getParamValue(value) {
      const isStringValue = typeof value === "string";
      const isBooleanValue = typeof value === "boolean";

      if (isStringValue) {
        return !value ? "Не указано" : value;
      } else if (isBooleanValue) {
        return value ? "Да" : "Нет";
      }

      return value;
    },
    getTitle(code) {
      const param = this.category?.parameters?.find(el => el.code === code);
      return param ? param.title : code;
    },
    async getCategory() {
      try {
        this.paramTitleIsLoading = true;
        const categoriesList = await this.categoryApi.api.categoriesList();
        this.category = categoriesList?.find(
          category => category.id === this.technique?.categoryId
        );
      } finally {
        this.paramTitleIsLoading = false;
      }
    },
    async getTechnique() {
      if (this.device?.techniqueId) {
        this.technique = await this.techniqueApi.getTechniqueFetch(
          this.device?.techniqueId
        );
        if (this.technique?.categoryId) {
          await this.getCategory();
        }
      }
    },
    showModalOrder() {
      this.$refs.plusButton.showModal();
    },
    createRequest() {
      this.$refs.plusButton.showModal();
    },
    getAuthor(item) {
      let label = "Не указан";

      item.permissions.forEach(value => {
        if (value?.roles[0] === "owner")
          label = value?.user?.firstName + " " + value?.user?.lastName;
      });

      return label;
    },
    goToChat(item) {
      this.$router.push({ name: "RequestChat", params: { id: item.order } });
    },
    async inputTag() {
      this.editGeotags = !!this.tagName;

      if (this.editGeotags) {
        const findGlobal = this.tagsList.results.find(
          el => el.title.toLowerCase() === this.tagName.toLowerCase()
        );
        if (!findGlobal) {
          const res = await RequestManager().createTag({ title: this.tagName });
          await this.addTag(res);
        } else {
          await this.addTag(findGlobal);
        }
      }
    },
    async addTag(tag) {
      const find = this.geoTags.find(
        el => el.title.toLowerCase() === tag.title.toLowerCase()
      );
      try {
        if (!find) {
          this.geoTags.push(tag);
          const arr = this.geoTags.map(el => el.id);
          await RequestManager().updateSingleDevice(this.$route.params.id, {
            tag_ids: arr
          });
        } else {
          alert("Такой тег уже прикреплен.");
        }
      } finally {
        this.tagName = "";
        this.editGeotags = false;
      }
    },
    onAddOrder(data) {
      this.$router.push({ path: "/my-request/chat/" + data.id });
    },
    async onDownloadClick() {
      /* const token = localStorage.token;
      const xhr = new XMLHttpRequest();
      xhr.open("GET", this.qrCodeStr);
      xhr.setRequestHeader("Authorization", "Bearer " + token);
      xhr.responseType = "blob";
      const name = "QR" + this.currentDevice.serialNum + ".png";
      xhr.onload = function() {
        saveAs(xhr.response, name);
      };

      xhr.onerror = function() {
        console.error("could not download file");
      };

      xhr.send(); */

      let path = "";
      let url = "";
      this.isQrCodesPrinting = true;
      if (this.isNeoPharm) {
        localStorage.setItem("currentQrURL", this.currentQrURL);
        localStorage.setItem("currentQrName", this.currentQrName);
        localStorage.setItem("currentQrPhone", this.currentQrPhone);
        const qrResponse = await RequestManager().qrApi.deviceQr(
          this.currentDevice.id,
          {
            force: true,
            url: this.currentQrURL,
            phone: this.currentQrPhone,
            title: this.currentQrName
          }
        );
        path = qrResponse.path;
        url = qrResponse.url;
      } else {
        const qrResponse = await RequestManager().qrApi.deviceQr(
          this.currentDevice.id,
          {
            force: true,
            url: `${location.host}/qrcodeorder`
          }
        );
        path = qrResponse.path;
        url = qrResponse.url;
      }
      console.log(path);
      this.isQrCodesPrinting = false;
      window.open(url, "_blank");
    },
    generateDate(data) {
      const date = new Date(data);
      const day =
        date.getDate().toString().length === 1
          ? "0" + date.getDate()
          : date.getDate();
      const month =
        (date.getMonth() + 1).toString().length === 1
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      const year = date.getFullYear();
      const dateStr = day + "." + month + "." + year;
      return dateStr;
    },
    addGeotag() {
      this.editGeotags = true;
      setTimeout(() => {
        this.$refs.tagInput.focus();
      }, 100);
    },
    deleteTagClick(id) {
      this.$store.dispatch("devices/deleteTag", id);
      // const tag_ids = this.geoTags
      //   .map(elem => elem.id)
      //   .filter(elem => elem !== id);
      // const data = { tag_ids };
      // this.updateTags(data);
      // this.geoTags = this.device.tags.filter(elem => elem.id !== id);
    },

    updateTags(data) {
      RequestManager().updateSingleDevice(this.device.id, data);
    }
  },

  // created() {
  //   RequestManager()
  //     .getSingleDevice(this.$route.params.id)
  //     .then(data => {
  //       const device = data;
  //       for (let key in device) {
  //         if (key === "attributes") {
  //           // device[key] = JSON.parse(device[key]);
  //         }
  //       }
  //       this.device = device;
  //
  //       this.getTechnique();
  //
  //       const params = {
  //         limit: this.device?.history?.length ? this.device.history.length : 21,
  //         device_id: this.device.id
  //       };
  //       RequestManager()
  //         .getOrdersListWithParams(params)
  //         .then(data => {
  //           this.ordersWithThisDevice = [
  //             ...this.ordersWithThisDevice,
  //             ...data?.results
  //           ];
  //           this.ordersWithThisDevice.sort(function(a, b) {
  //             return new Date(b.created) - new Date(a.created);
  //           });
  //         });
  //     });
  //   this.qrCodeStr =
  //     process.env.VUE_APP_API_URL +
  //     "/devices/" +
  //     this.$route.params.id +
  //     "/qr/";
  // },
  async mounted() {
    await this.$store.dispatch("devices/getDevice", this.$route.params.id);
    this.device = this.$store.state.devices.currentDevice;
    this.isNeoPharm = this.device.companyId === "425";
    const currentQrURLSrc = localStorage.getItem("currentQrURL");
    if (currentQrURLSrc) {
      this.currentQrURL = currentQrURLSrc;
    } else {
      this.currentQrURL = this.isNeoPharm
        ? `${location.host}/qrcodeorder-neopharm`
        : `${location.host}/qrcodeorder`;
    }
    const currentQrPhoneSrc = localStorage.getItem("currentQrPhone");
    if (currentQrPhoneSrc) {
      this.currentQrPhone = currentQrPhoneSrc;
    } else {
      this.currentQrPhone = "";
    }
    const currentQrNameSrc = localStorage.getItem("currentQrName");
    if (currentQrNameSrc) {
      this.currentQrName = currentQrNameSrc;
    } else {
      this.currentQrName = "";
    }
    this.getTechnique();
    await this.getTechniqueCounters();
    await this.getTechniqueCountersHistory();
    await this.getOrders();
    await this.get_offices();
    this.geoTags = this.currentDevice.tags;
    //  console.log("this.$route.params.mode",this.$route.params.mode);
  }
};
</script>

<style lang="scss" scoped>
.qr-block {
  margin: 20px auto;
  width: 200px;
  height: 200px;
  border: 1px solid black;
  img {
    width: 100%;
    height: 100%;
  }
}
.download-block {
  width: 20px;
  height: 20px;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
}
.qr-button {
  outline: none;
  border: 2px solid #016b90;
  color: #016b90;
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: 700;
  background: var(--main-item-bg);
}
@media (min-width: 320px) and (max-width: 375px) {
  .qr-button {
    padding: 0 20px 0 20px;
  }
}

.device-history {
  .author {
    color: var(--main-text-color);
    font-weight: bold;
    font-size: 12px;
  }
}

.scroll-container {
  width: 100%;
}
.delete-tag-icon {
  position: relative;
  font-weight: 600;
  right: -10px;
  cursor: pointer;
}
.tehnique-wrap {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
}
@media (min-width: 320px) and (max-width: 375px) {
  .tehnique-wrap {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    text-align: center;
    border-radius: 6px;
    background-color: #ffffff;
  }
}

.tehnique-info-wrap {
  width: 100%;
  background: var(--main-item-bg);
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 6px;

  padding: 23px 42px;
}

.tehnique-block {
  padding: 23px 36px 20px;
  min-height: 211px;
  width: 90%;

  background: var(--main-item-bg);
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 6px;

  &:not(:last-child) {
    margin-right: 21px;
  }
}

@media (min-width: 320px) and (max-width: 375px) {
  .tehnique-block__img-block {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 10px;
    margin: 0;
    border-radius: 6px;
    .tehnique-block__img {
      display: block;
      padding: 0;
    }
  }
}
@media (min-width: 376px) and (max-width: 425px) {
  .tehnique-block {
    width: 100%;
    margin: 0;
    padding-left: 20px;
    &:not(:last-child) {
      margin: 0;
      .tehnique-block__img {
        display: block;
        height: 60px;
        width: 90px;
      }
    }
  }
}
@media (min-width: 426px) and (max-width: 768px) {
  .tehnique-block {
    width: 100%;
    margin: 0;
    padding-left: 20px;
  }
  .tehnique-block__img {
    display: block;
    height: 150px;
    width: 120px;
  }
}

.show-history {
  button {
    border: 1px solid #006b90;
    background: #006b90;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.04em;
    padding: 15px 20px;
    color: #ffffff;
  }
}

.tehnique-block__history {
  padding: 23px 36px 20px;

  background: #ffffff;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 6px;

  &:not(:last-child) {
    margin-right: 21px;
  }
}

.tehnique-block__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px;
}

.tehnique-block__img {
  display: block;
  margin: 0 auto;
  margin-bottom: 22px;
  height: 80px;
  @media (max-width: 0) {
    display: none;
  }
}

.tehnique-block__title {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: var(--card-text-color);

  &_center {
    font-weight: 500;
    text-align: center;
  }
}

.tehnique-block__mileage {
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  letter-spacing: 0.04em;

  color: #006b90;
  text-align: center;
}

.tehnique-block__info {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: var(--main-text-color);
  .geotag-item {
    background: var(--geotag-color);
    border-radius: 10px;
    padding: 7px 20px;
    font-size: 12px;
    border: 2px solid #e6f1f4;
    box-sizing: border-box;
  }
  .geotag-plus-btn {
    background: var(--main-card-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #016b90;
    font-size: 50px;
    color: #016b90;
    border-radius: 10px;
    width: 34px;
    height: 34px;
    cursor: pointer;
    box-sizing: border-box;
  }
  .geotag-input {
    background: var(--main-card-color);
    color: var(--main-text-color);
    border: 2px solid #016b90;
    padding-left: 10px;
    border-radius: 10px;
    min-width: 20px;
    height: 34px;
    outline: none;
  }
}

.tehnique-block__id {
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.04em;

  color: var(--main-text-color);
  font-weight: bold;
}

.tehnique-block__address {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;

  color: var(--main-text-color);
}

.tehnique-block__address-img {
  margin-right: 12px;
}

.tehnique-block__btn-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.tehnique-block__btn {
  width: 139px;
  border: 1px solid #006b90;
  background: #006b90;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 6px;

  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  text-align: center;
  letter-spacing: 0.04em;
  color: #ffffff;
}

.history-table__wrapper {
  font-size: 14px;
}

.tehnique-block__btn-text {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;

  color: #4b4b67;
}

.colored_row {
  padding: 10px;
  border: 1px solid #006b90;
  // border-radius: 5px;
  // margin-bottom: 15px;
  color: #006b90;
  background-color: rgba(81, 162, 190, 0.15);
  width: 80%;
}
.col-fixed-width {
  width: 20%;
}

// .table-block {
//   margin: 0 auto;
//   display: flex;
//   flex-direction: column;
//   border: 2px solid #daebf0;
//   width: 85%;
//   border-radius: 10px;
//   overflow: hidden;
//   margin-bottom: 20px;
//   .table-header {
//     display: flex;
//     width: 100%;
//     border-radius: 10px 0 0 10px;
//     > div {
//       flex-basis: 50%;
//       background: #e6f1f4;
//       padding: 10px 30px;
//     }
//   }
//   .table-block-item {
//     width: 100%;
//     display: flex;
//     border-top: 1px solid #daebf0;
//     font-size: 14px;
//     > div {
//       flex-basis: 50%;
//       // background: #e6f1f4;
//       padding: 10px 30px;
//     }
//   }
//   :last-child {
//     border-left: 1px solid #daebf0;
//   }
//   .colored {
//     background: #f3f9fb;
//   }
// }
.no-button-row {
  text-align: left;
  margin-left: 48px;
}
</style>
